import { read, WorkBook } from 'xlsx';
import { QuizType } from '@/types/enum/QuizType';
import { VehicleType } from '@/types/enum/VehicleType';

const mappingDe = {
  T1_Beschaffung: {
    title: 'Beschaf­fung',
    description: '',
    topic: QuizType.PROCUREMENT,
    info_link: 'https://quinwalo.de/',
  },
  T2_Wasserstraßen: {
    title: 'Wasser­straßen',
    description: '',
    topic: QuizType.WATERWAY,
    info_link: 'https://quinwalo.de/',
  },
  'T3_deutsche Binnenhäfen': {
    title: 'Binnen­häfen',
    description: '',
    topic: QuizType.PORTS,
    info_link: 'https://quinwalo.de/',
  },
  T4_Transportrecht: {
    title: 'Transport­recht',
    description: '',
    topic: QuizType.LAW,
    info_link: 'https://quinwalo.de/',
  },
  T5_Rheinschifffahrt: {
    title: 'Rhein­schiff­fahrt',
    description: '',
    topic: QuizType.SHIPPING,
    info_link: 'https://quinwalo.de/',
  },
  'T6_Transport im Wandel': {
    title: 'Transport im Wandel',
    description: '',
    topic: QuizType.TRANSITION,
    info_link: 'https://quinwalo.de/',
  },
  T7_Klimawandel: {
    title: 'Klima­wandel',
    description: '',
    topic: QuizType.CLIMATE,
    info_link: 'https://quinwalo.de/',
  },
  T8_Standortfaktoren: {
    title: 'Standort­faktoren',
    description: '',
    topic: QuizType.FACTORS,
    info_link: 'https://quinwalo.de/',
  },
};

const mappingEn = {
  T1_Beschaffung: {
    title: 'procurement',
    description: '',
    topic: QuizType.PROCUREMENT,
    info_link: 'https://quinwalo.de/',
  },
  T2_Wasserstraßen: {
    title: 'waterways',
    description: '',
    topic: QuizType.WATERWAY,
    info_link: 'https://quinwalo.de/',
  },
  'T3_deutsche Binnenhäfen': {
    title: 'ports',
    description: '',
    topic: QuizType.PORTS,
    info_link: 'https://quinwalo.de/',
  },
  T4_Transportrecht: {
    title: 'law',
    description: '',
    topic: QuizType.LAW,
    info_link: 'https://quinwalo.de/',
  },
  T5_Rheinschifffahrt: {
    title: 'Rhine shipping',
    description: '',
    topic: QuizType.SHIPPING,
    info_link: 'https://quinwalo.de/',
  },
  'T6_Transport im Wandel': {
    title: 'transition',
    description: '',
    topic: QuizType.TRANSITION,
    info_link: 'https://quinwalo.de/',
  },
  T7_Klimawandel: {
    title: 'climate change',
    description: '',
    topic: QuizType.CLIMATE,
    info_link: 'https://quinwalo.de/',
  },
  T8_Standortfaktoren: {
    title: 'factors',
    description: '',
    topic: QuizType.FACTORS,
    info_link: 'https://quinwalo.de/',
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any*/
/*export async function importFromExcel(language: string): Promise<any> {
  const f = await fetch(`/assets/data/quiz-${language}.xlsx`, {
    method: 'GET',
    withCredentials: true,
    crossorigin: true,
    mode: 'no-cors',
  } as any);
  const ab = await f.arrayBuffer();
  const wb = read(ab);
  return convertExcelToJson(language, wb);
}*/

export async function importFromUpload(
  file: File,
  language: string
): Promise<any> {
  const ab = await file.arrayBuffer();
  const wb = read(ab);
  return convertExcelToJson(language, wb);
}

export function convertExcelToJson(language: string, wb: WorkBook): any {
  const mapping = language === 'de' ? mappingDe : mappingEn;
  const json: any[] = [];
  let questionId = 0;
  let optionId = 0;
  for (let quizId = 0; quizId < wb.SheetNames.length; quizId++) {
    const sheetName = wb.SheetNames[quizId];
    const quiz = {
      id: quizId,
      title: mapping[sheetName].title,
      description: mapping[sheetName].description,
      topic: mapping[sheetName].topic,
      info_link: mapping[sheetName].info_link,
      questions: [] as any[],
    };
    const sheet = wb.Sheets[sheetName];
    const lastCellKey = Object.keys(sheet).findLast((key) =>
      key.startsWith('A')
    );
    if (!lastCellKey) continue;
    const rowCount = parseInt(lastCellKey.substring(1));
    if (mapping[sheetName].topic === QuizType.PROCUREMENT) {
      const startIndex = 9;
      for (
        let questionIndex = 0;
        questionIndex <= rowCount - startIndex;
        questionIndex++
      ) {
        const cellIndex = questionIndex + startIndex;
        if (!sheet[`B${cellIndex}`]) break;
        const question = {
          id: questionId,
          title: sheet[`B${cellIndex}`]?.w,
          description: '',
          hint: sheet[`F${cellIndex}`]?.w,
          explanation: sheet[`G${cellIndex}`]?.w ?? '',
          options: [
            {
              id: optionId++,
              title: 'Binnenschiff',
              description: '',
              vehicle: VehicleType.SHIP,
              correct: sheet[`C${cellIndex}`]?.w === 'x',
            },
            {
              id: optionId++,
              title: 'Bahn',
              description: '',
              vehicle: VehicleType.TRAIN,
              correct: sheet[`D${cellIndex}`]?.w === 'x',
            },
            {
              id: optionId++,
              title: 'LKW',
              description: '',
              vehicle: VehicleType.TRUCK,
              correct: sheet[`E${cellIndex}`]?.w === 'x',
            },
          ],
        };
        quiz.questions.push(question);
        questionId++;
      }
    } else {
      const startIndex = 2;
      for (
        let questionIndex = 0;
        questionIndex <= rowCount - startIndex;
        questionIndex++
      ) {
        const cellIndex = questionIndex + startIndex;
        if (!sheet[`C${cellIndex}`]) break;
        const result = sheet[`C${cellIndex}`].w.toString();
        const question = {
          id: questionId,
          title: sheet[`B${cellIndex}`]?.w,
          description: '',
          hint: sheet[`H${cellIndex}`]?.w,
          explanation: sheet[`I${cellIndex}`]?.w ?? '',
          options: [
            {
              id: optionId++,
              title: sheet[`D${cellIndex}`]?.w,
              description: '',
              correct: result.includes('1'),
            },
            {
              id: optionId++,
              title: sheet[`E${cellIndex}`]?.w,
              description: '',
              correct: result.includes('2'),
            },
            {
              id: optionId++,
              title: sheet[`F${cellIndex}`]?.w,
              description: '',
              correct: result.includes('3'),
            },
            {
              id: optionId++,
              title: sheet[`G${cellIndex}`]?.w,
              description: '',
              correct: result.includes('4'),
            },
          ],
        };
        quiz.questions.push(question);
        questionId++;
      }
    }
    json.push(quiz);
  }
  return json;
}
