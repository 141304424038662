import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import QuizView from '@/views/QuizView.vue';
import ParseInput from '@/views/ParseInput.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: (route) => ({ lang: route.query.lang }),
  },
  {
    path: '/parse',
    name: 'parse',
    component: ParseInput,
  },
  {
    path: '/quiz/:id',
    name: 'quiz',
    component: QuizView,
    props: (route) => ({
      id: parseInt(route.params.id as string),
      lang: route.query.lang,
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
