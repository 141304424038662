import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46247096"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "hint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sprite = _resolveComponent("sprite")!
  const _component_animated_sprite = _resolveComponent("animated-sprite")!
  const _component_Application = _resolveComponent("Application")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_quiz_question = _resolveComponent("quiz-question")!

  return (_openBlock(), _createBlock(_component_quiz_question, {
    question: _ctx.question,
    answers: _ctx.answers,
    showHint: _ctx.showHint,
    showInfo: _ctx.showInfo,
    isCorrect: _ctx.isCorrect,
    "question-no": _ctx.questionNo,
    "state-list": _ctx.stateList,
    "can-confirm": _ctx.canConfirm,
    onConfirm: _ctx.confirm,
    style: _normalizeStyle({
      '--optionWidth': `${_ctx.optionWidth}px`,
      '--optionHeight': `${_ctx.optionHeight}px`,
      '--containerWidth': `${_ctx.containerWidth}px`,
      '--containerHeight': `${_ctx.containerHeight}px`,
      '--containerOptionWidth': `${_ctx.containerOptionWidth}px`,
      '--containerOptionHeight': `${_ctx.containerOptionHeight}px`,
      '--optionX': `${_ctx.optionX}px`,
      '--optionY': `${_ctx.optionY}px`,
      '--containerX': `${_ctx.animationProps.backgroundPositionX}px`,
      '--containerY': `${_ctx.containerY}px`,
      '--environmentWidth': `${_ctx.environmentWidth}px`,
      '--environmentHeight': `${_ctx.environmentHeight}px`,
      '--storeFactor': _ctx.storeFactor,
      '--containerBackgroundColor': _ctx.animationConfig.container.backgroundColor,
      '--containerBackgroundRadius': _ctx.animationConfig.container.backgroundRadius,
    })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        ref: "canvasContainer",
        class: "container-environment",
        style: _normalizeStyle({
          backgroundImage: `url(/assets/visualization/${_ctx.animationConfig.background})`,
        })
      }, [
        (_ctx.isContainerSet)
          ? (_openBlock(), _createBlock(_component_Application, {
              key: 0,
              class: "canvas-container",
              ref: "pixi",
              width: _ctx.environmentWidth,
              height: _ctx.environmentHeight,
              transparent: true,
              backgroundAlpha: 0,
              style: _normalizeStyle({
            zIndex: _ctx.canvasZIndex,
            pointerEvents: 'none',
          })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_sprite, {
                  texture: _ctx.animationSprite.textures[_ctx.animationConfig.container.image],
                  height: _ctx.containerHeight,
                  width: _ctx.containerWidth,
                  anchor: 0,
                  x: _ctx.animationProps.backgroundPositionX,
                  y: _ctx.containerY
                }, null, 8, ["texture", "height", "width", "x", "y"]),
                (_ctx.animationConfig.result)
                  ? (_openBlock(), _createBlock(_component_animated_sprite, {
                      key: 0,
                      ref: "approved",
                      textures: 
              _ctx.animationSprite.animations[
                _ctx.animationConfig.result.approved.animation
              ]
            ,
                      "animation-speed": 0.1,
                      width: _ctx.environmentHeight * _ctx.animationConfig.result.approved.width,
                      height: _ctx.environmentHeight * _ctx.animationConfig.result.approved.height,
                      x: 
              _ctx.environmentWidth * _ctx.animationConfig.result.approved.x +
              _ctx.animationProps.backgroundPositionX
            ,
                      y: _ctx.environmentHeight * _ctx.animationConfig.result.approved.y,
                      loop: false,
                      playing: _ctx.isCorrect && _ctx.showInfo,
                      onComplete: _ctx.animationCompleted
                    }, null, 8, ["textures", "width", "height", "x", "y", "playing", "onComplete"]))
                  : _createCommentVNode("", true),
                (_ctx.animationConfig.result)
                  ? (_openBlock(), _createBlock(_component_animated_sprite, {
                      key: 1,
                      ref: "rejected",
                      textures: 
              _ctx.animationSprite.animations[
                _ctx.animationConfig.result.rejected.animation
              ]
            ,
                      "animation-speed": 0.1,
                      width: _ctx.environmentHeight * _ctx.animationConfig.result.rejected.width,
                      height: _ctx.environmentHeight * _ctx.animationConfig.result.rejected.height,
                      x: 
              _ctx.environmentWidth * _ctx.animationConfig.result.rejected.x +
              _ctx.animationProps.backgroundPositionX
            ,
                      y: _ctx.environmentHeight * _ctx.animationConfig.result.rejected.y,
                      loop: false,
                      playing: !_ctx.isCorrect && _ctx.showInfo,
                      onComplete: _ctx.animationCompleted
                    }, null, 8, ["textures", "width", "height", "x", "y", "playing", "onComplete"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["width", "height", "style"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["container-store-space", _ctx.animationConfig.option.alignment])
          }, [
            _createVNode(_component_draggable, {
              modelValue: _ctx.selectionList,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectionList) = $event)),
              "item-key": "id",
              group: "option",
              class: "container-store",
              onChange: _ctx.changeContainer,
              onEnd: _ctx.endDrag
            }, {
              item: _withCtx(({ element }) => [
                _createVNode(_component_el_card, { class: "option" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: "image",
                        style: _normalizeStyle({
                        '--color': _ctx.animationConfig.option.tint
                          ? element.settings.color
                          : 'transparent',
                        '--opacity': _ctx.animationConfig.option.tint
                          ? '50%'
                          : '100%',
                      })
                      }, [
                        _createVNode(_component_el_tooltip, {
                          content: element.title
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: _ctx.animationImages[element.settings.image],
                              alt: "option"
                            }, null, 8, _hoisted_2)
                          ]),
                          _: 2
                        }, 1032, ["content"])
                      ], 4)
                    ]),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(element.title), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["modelValue", "onChange", "onEnd"])
          ], 2)
        ])
      ], 4)
    ]),
    default: _withCtx(() => [
      (_ctx.animationSprite)
        ? (_openBlock(), _createBlock(_component_draggable, {
            key: 0,
            modelValue: _ctx.storeList,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeList) = $event)),
            "item-key": "id",
            group: { name: 'option', pull: 'clone' },
            clone: _ctx.addToSelection,
            class: _normalizeClass(["store", _ctx.animationConfig.option.alignment]),
            handle: ".interactable",
            onChange: _ctx.removeFromSelection,
            onStart: _ctx.startDrag,
            onEnd: _ctx.endDrag
          }, {
            item: _withCtx(({ element }) => [
              _createVNode(_component_el_card, {
                class: _normalizeClass(["option", {
              interactable: !_ctx.isSelected(element),
              disabled: _ctx.isSelected(element),
            }])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", {
                      class: "image",
                      style: _normalizeStyle({
                  '--color': _ctx.animationConfig.option.tint
                    ? element.settings.color
                    : 'transparent',
                  '--opacity': _ctx.animationConfig.option.tint ? '50%' : '100%',
                })
                    }, [
                      _createVNode(_component_el_tooltip, {
                        content: 
                    element.description ? element.description : element.title
                  
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: _ctx.animationImages[element.settings.image],
                            alt: "option"
                          }, null, 8, _hoisted_4)
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ], 4)
                  ]),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(element.title), 1)
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 1
          }, 8, ["modelValue", "clone", "class", "onChange", "onStart", "onEnd"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(`views.quiz.drag.${_ctx.quiz.topic}`)), 1)
    ]),
    _: 1
  }, 8, ["question", "answers", "showHint", "showInfo", "isCorrect", "question-no", "state-list", "can-confirm", "onConfirm", "style"]))
}