import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { apiErrorHandling } from '@/services/exception-service';
import * as env from '@/utils/env';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export const endpoint = (
  options?: Partial<AxiosRequestConfig>,
  baseURL = `${env.getString('VUE_APP_API_PATH')}`
): AxiosInstance => {
  const config = {
    baseURL: baseURL,
    ...options,
  };
  if (!config.baseURL.endsWith('/')) {
    config.baseURL = `${config.baseURL}/`;
  }
  const axiosInstance = Axios.create(config);

  // response interceptors are used to catch errors globally to show a generic error message
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      apiErrorHandling(error);
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const apiEndpoint = (
  options?: Partial<AxiosRequestConfig>
): AxiosInstance => {
  return endpoint(options, `${env.getString('VUE_APP_API_PATH')}`);
};

export const API_ENDPOINT = apiEndpoint();

export const getApiEndpoint = (): AxiosInstance => {
  return API_ENDPOINT;
};

export async function apiExecuteGetHandled<T = any>(
  url: string,
  empty: T | any = {}
): Promise<T> {
  try {
    return await apiExecuteGet<T>(url);
  } catch (error: any) {
    if (error.message == 'Network Error') {
      try {
        return await apiExecuteGet<T>(url);
      } catch (error) {
        return empty as T;
      }
    }
    return empty as T;
  }
}

export async function apiExecuteGet<T = any>(url: string): Promise<T> {
  const { data: result } = await getApiEndpoint().get<T>(url);
  return result;
}

export async function apiExecutePostHandled<T = any>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body: any,
  empty: T | any = {}
): Promise<T> {
  try {
    return await apiExecutePost(url, body);
  } catch (error) {
    return empty as T;
  }
}

export async function apiExecutePost<T = any>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body: any
): Promise<T> {
  const { data: result } = await getApiEndpoint().post<T>(url, body);
  return result;
}
