/* eslint-disable @typescript-eslint/no-explicit-any*/

export function readJSON(file: string): any {
  const request = new XMLHttpRequest();
  request.open('GET', file, false);
  request.setRequestHeader(
    'Cache-Control',
    'no-cache, no-store, must-revalidate, max-age=0'
  );
  request.setRequestHeader('Pragma', 'no-cache');
  request.setRequestHeader('Expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
  request.send(null as any);
  if (request.status == 200) return JSON.parse(request.responseText);
}
